<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Food Merchant</h4>
    </vs-row>

    <vs-row vs-w="6">
      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Category</span>
        <v-select
          label="name"
          :options="featureCategoryOptions"
          :reduce="(category) => category.id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="payload.feature_category_id"
        />
      </vs-col>
      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          label="Merchant Name (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>
      <vs-col vs-w="12" class="mb-3">
        <p class="text-sm">Description (*)</p>
        <vs-textarea class="w-full" v-model="payload.description" />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("description")
        }}</span>
      </vs-col>
      <vs-col vs-w="6">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="url" class="rounded mr-4" width="300" height="300" />
          <div>
            <input
              type="file"
              @change="addImage"
              class="hidden"
              ref="update_logo_input"
              accept="image/*"
            />
            <vs-button
              @click="$refs.update_logo_input.click()"
              class="mr-4 mb-2"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              @click="removeImage"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
            ></vs-button>
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("logo")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2" :disabled="loading">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";
// Store Module
import foodMerchantStore from "@/store/food-merchant";

export default {
  name: "foodMerchantEdit",

  metaInfo: {
    title: "Edit Food Merchant",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    vSelect,
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },
  },

  data: () => ({
    payload: {
      name: "",
      feature_category_id: "",
      logo: null,
      description: "",
    },
    url: null,
    loading: false,
    featureCategoryOptions: [],
  }),

  methods: {
    getFeatureCategories() {
      axios
        .get("/vancynet/food-merchant-category")
        .then(({ data: res }) => {
          this.featureCategoryOptions = res.data.map((category) => {
            return {
              id: category.id,
              name: category.name,
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },
    async getData() {
      const response = await this.$store.dispatch(
        "foodMerchant/fetchFoodMerchant",
        this.decryptedID
      );
      this.payload.name = response.message.name;
      this.payload.description = response.message.description;
      this.url = response.message.logo;
      this.payload.feature_category_id =
        response.message.food_merchant_category.id;
      this.$vs.loading.close();
    },

    async storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("description", this.payload.description);

      formData.set(
        "food_merchant_category_id",
        this.payload.feature_category_id
      );
      if (this.payload.logo) {
        formData.set("logo", this.payload.logo);
      }
      formData.append("_method", "PUT");
      this.loading = true;
      const response = await this.$store.dispatch(
        "foodMerchant/updateFoodMerchant",
        {
          id: this.decryptedID,
          body: formData,
        }
      );
      this.loading = false;
      if (!response.success) {
        this.$catchErrorResponse(response.message);
        return;
      }

      this.$vs.notify({
        title: "Successful",
        text: response.msg_status,
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
      });

      this.$router.push("/food-merchant");
    },
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.logo = file;
    },
    removeImage() {
      this.url = null;
      this.payload.logo = "";
    },
  },

  created() {
    if (!foodMerchantStore.isRegistered) {
      this.$store.registerModule("foodMerchant", foodMerchantStore);
      foodMerchantStore.isRegistered = true;
    }

    this.getFeatureCategories();
    this.getData();
  },
};
</script>

<style></style>
